// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Cx from "rescript-classnames/src/Cx.res.js";

var form = Cx.cx([
      "flex",
      "flex-col",
      "gap-4",
      "w-1/2"
    ]);

var buttonContainer = Cx.cx([
      "flex",
      "gap-4",
      "flex-row-reverse"
    ]);

export {
  form ,
  buttonContainer ,
}
/* form Not a pure module */
